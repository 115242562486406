import React from "react"
import { Box, Container, Flex, Heading, Grid } from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
import VideoThumb from "../videoThumb"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { getLatestVideoPage } from "../../utils/path"
import { useBreakpointIndex } from "@theme-ui/match-media"

const LatestVideos = ({ videos }) => {
  videos = videos.slice(0, 7)
  const index = useBreakpointIndex()
  return (
    <Box sx={{ position: "relative", my: [7] }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container sx={{}}>
              <Box
                sx={{
                  pt: [6],
                  borderTop: "1px solid",
                  borderColor: "dark",
                }}
              ></Box>
            </Container>
            <Container
              variant={index > 1 ? "oneSideRight" : "container"}
              sx={{}}
            >
              <Grid columns={["1fr", "1fr", ".2fr .8fr"]} gap={[0, 3]}>
                <Flex
                  sx={{
                    flexDirection: ["row", "row", "column"],
                    justifyContent: [
                      "space-between",
                      "space-between",
                      "flex-start",
                      "flex-start",
                    ],
                    alignItems: ["center", "flex-start"],
                    mb: [5],
                  }}
                >
                  <Heading as="h2" variant="h2" sx={{ mt: [0], mb: [0, 5] }}>
                    {t.thelatestTitle}
                  </Heading>
                  <Box sx={{
                    display:["none","none","block","block"]
                  }}>
                    <InboundLink
                      variant="links.badge.full"
                      to={getLatestVideoPage(
                        videos[0].category.channel.pageLink.slug,
                        videos[0].locale
                      )}
                    >
                      {t.viewAll}
                    </InboundLink>
                  </Box>
                </Flex>
                {index > 1 ? (
                  <Box
                    sx={{
                      ".swiper-container": {
                        pb: [0],
                      },
                      ".swiper-slide": {
                        height: "auto",
                      },
                    }}
                  >
                    <Swiper
                      grabCursor={true}
                      spaceBetween={16}
                      preventClicks={true}
                      preventClicksPropagation={true}
                      autoHeight={false}
                      breakpoints={{
                        320: {
                          slidesPerView: 1.2,
                        },
                        640: {
                          slidesPerView: 2.2,
                        },
                        896: {
                          slidesPerView: 3.3,
                        },
                      }}
                    >
                      {videos.map(video => (
                        <>
                          <SwiperSlide key={video.id}>
                            <VideoThumb video={video} small />
                          </SwiperSlide>
                        </>
                      ))}
                    </Swiper>
                  </Box>
                ) : (
                  <Grid columns={["1fr"]}>
                    {videos.map(video => (
                      <VideoThumb video={video} />
                    ))}
                  </Grid>
                )}
                <Box sx={{
                    pt:[8,0],
                    textAlign:"center",
                    display:["block","block","none","none"]
                  }}>
                    <InboundLink
                      variant="links.badge.full"
                      to={getLatestVideoPage(
                        videos[0].category.channel.pageLink.slug,
                        videos[0].locale
                      )}
                    >
                      {t.viewAll}
                    </InboundLink>
                  </Box>
              </Grid>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default LatestVideos
