import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import { i18nContext } from "../context/i18nContext"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import { Link } from "gatsby"

const AboutUsSection = ({ data }) => {
  const { title, description, link } = data

  return (
    <Box sx={{ position: "relative", py: [6, 7] }}>
      <i18nContext.Consumer>
        {t => (
          <Container>
            <Grid
              columns={[1, 1, "1fr"]}
              gap={[4]}
              sx={{ m: ["0 auto"], maxWidth: "565px" }}
            >
              <Box sx={{}}>
                <Heading
                  as="h2"
                  variant="h2"
                  color="dark"
                  sx={{ fontSize: [7, 7], my: [0] }}
                >
                  {title}
                </Heading>
              </Box>
              <Text
                as="div"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <Box>
                <MagicLink
                  item={link}
                  variant="links.badge.full"
                  sx={{
                    display: "inline-block",
                  }}
                >
                  {link.anchor}
                </MagicLink>
              </Box>
            </Grid>
          </Container>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default AboutUsSection
