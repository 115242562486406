import React, { createRef, useEffect, useState } from "react"
import { Box, Container, Text, Heading } from "@theme-ui/components"
import ReactPlayer from "react-player"
import LoadingSpinner from "../loadingSpinner"
import { Script as GatsbyScript, ScriptStrategy } from "gatsby"

const FormEmbed = ({ pageTitle, title, code }) => {
  const loader = createRef()
  const tracking = createRef()
  const [dataFormBlockId, setDataFormBlockId] = useState("")
  const [scripts, setScripts] = useState([])
  const [divId, setDivId] = useState("")
  const [dataWebsiteId, setDataWebsiteId] = useState("")
  const [dataHostname, setDataHostname] = useState("")
  const [divClassName, setDivClassName] = useState("")

  useEffect(() => {
    var parser = new DOMParser()
    var htmlDoc = parser.parseFromString(code, "text/html")

    setDataFormBlockId(
      htmlDoc
        .querySelector("div[data-form-block-id]")
        .getAttribute("data-form-block-id")
    )

    let tmpScripts = []
    htmlDoc.querySelectorAll("script").forEach(e => {
      tmpScripts.push(e.getAttribute("src"))
    })
    setScripts(tmpScripts)

    setDivId(htmlDoc.querySelector("div[id]").getAttribute("id"))
    setDataWebsiteId(
      htmlDoc
        .querySelector("div[data-website-id]")
        .getAttribute("data-website-id")
    )
    setDataHostname(
      htmlDoc.querySelector("div[data-hostname]").getAttribute("data-hostname")
    )

    setDivClassName(
      htmlDoc.querySelector("div[data-hostname]").getAttribute("class")
    )
  }, [])

  // do whatever you want with htmlDoc.getElementsByTagName('a');

  return (
    scripts.length > 0 && (
      <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
        <Container>
          <Box
            sx={{
              maxWidth: "900px",
              mb:[6,9]
            }}
          >
            <Heading
              as="h2"
              variant="h2"
              sx={{
                fontSize: [6, 7],
                my: [0],
                textTransform: "capitalize",
                color: "primary",
              }}
            >
              {pageTitle}
            </Heading>
            <Heading
              as="h2"
              variant="h2"
              color="dark"
              sx={{ fontSize: [6, 7], my: [0] }}
            >
              {title}
            </Heading>
          </Box>
          <Box data-form-block-id={dataFormBlockId} />
          {scripts.map(script => (
            <GatsbyScript
              src={`${scripts}`}
              strategy="idle"
              onLoad={() => console.log(`success loading `)}
              onError={() => console.log(`failure loading `)}
            />
          ))}
          <Box id={divId} />
          <Box
            className={divClassName}
            style={{ display: "none" }}
            data-website-id={dataWebsiteId}
            data-hostname={dataHostname}
          />
        </Container>
      </Box>
    )
  )
}

export default FormEmbed
