import React, { useEffect } from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
import { getCategoryPath, getVideoPath } from "../../utils/path"
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { GatsbyImage } from "gatsby-plugin-image"
import { Play } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"
import VideoThumbOriginal from "../videoThumb"

const PinnedCategory = ({ block, videos }) => {
  const { category } = block
  const index = useBreakpointIndex()
  return (
    <Box
      sx={{
        position: "relative",
        my: [4, 6],
        backgroundColor: "primary",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Container sx={{}}>
              <Box
                sx={{
                  pt: [6, 6, 10],
                  color: "light",
                  pb: [6, 6, 10],
                }}
              >
                <Flex
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: [4],
                  }}
                >
                  <Heading
                    as="h2"
                    variant="h2"
                    sx={{ my: [0], color: "light" }}
                  >
                    {block.category.title}
                  </Heading>
                  <InboundLink
                    to={getCategoryPath(category, category.locale)}
                    variant="links.badge.category"
                    sx={{
                      backgroundColor: "light",
                      color: "primary",
                      mx: [2],
                      my: [2],
                    }}
                  >
                    {t.viewAll}
                  </InboundLink>
                </Flex>
                {index > 1 ? (
                  <Grid
                    sx={{
                      gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
                      rowGap: "1rem",
                      columnGap: "1rem",
                      ".cell--1": {
                        gridColumn: ["1 / 3", "1 / 3"],
                        gridRow: ["1 / 3", "1 / 3"],
                      },
                      ".cell--2": {
                        gridColumn: ["3 / 3", "3 / 3"],
                        gridRow: ["1 / 1", "1 / 1"],
                      },
                      ".cell--3": {
                        gridColumn: ["4 / 4", "4 / 4"],
                        gridRow: ["1 / 1", "1 / 1"],
                      },
                      ".cell--4": {
                        gridColumn: ["3 / 3", "3 / 3"],
                        gridRow: ["2 / 2", "2 / 2"],
                      },
                      ".cell--5": {
                        gridColumn: ["4 / 4", "4 / 4"],
                        gridRow: ["2 / 2", "2 / 2"],
                      },
                      ".cell--6": {
                        gridColumn: ["1 / 1", "1 / 1"],
                        gridRow: ["3 / 3", "3 / 3"],
                      },
                      ".cell--7": {
                        gridColumn: ["1 / 1", "1 / 1"],
                        gridRow: ["4 / 4", "4 / 4"],
                      },
                      ".cell--8": {
                        gridColumn: ["2 / 4", "2 / 4"],
                        gridRow: ["3/ 5", "3/ 5"],
                      },
                      ".cell--9": {
                        gridColumn: ["4 / 4", "4 / 4"],
                        gridRow: ["3/ 3", "3/ 3"],
                      },
                      ".cell--10": {
                        gridColumn: ["4 / 4", "4 / 4"],
                        gridRow: ["4/ 4", "4/ 4"],
                      },
                    }}
                  >
                    {videos.slice(0, 10).map((video, index) => (
                      <Box className={`cell--${index + 1}`}>
                        <VideoThumb video={video} index={index + 1} />
                      </Box>
                    ))}
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      ".swiper-container": {
                        pb: [6],
                      },
                      ".swiper-slide": {
                        height: "auto",
                      },
                      ".swiper-pagination-bullet,.swiper-pagination-bullet-active-main":
                        {
                          backgroundColor: "light",
                          opacity: "1",
                        },
                    }}
                  >
                    <Swiper
                      autoHeight={false}
                      slidesPerView={1}
                      pagination={{ clickable: true, dynamicBullets: true }}
                    >
                      {videos.map(video => (
                        <>
                          <SwiperSlide key={video.id}>
                            <VideoThumbOriginal video={video} />
                          </SwiperSlide>
                        </>
                      ))}
                    </Swiper>
                  </Box>
                )}
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const VideoThumb = ({ video, index }) => {
  const primary = getColor(themeUiTheme, "primary")
  return (
    <InboundLink
      sx={{ display: "flex", height: "100%", width: "100%" }}
      to={getVideoPath(video, video.locale)}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          height: "100%",
          zIndex: 0,
          "&:hover": {
            img: {
              transition: "all .3s ease-in-out",
              transform: "scale(1.2)",
            },
          },
          ".gatsby-image-wrapper": {
            width: "100%",
            height: "100%",
            position: "relative",
            ":after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "dark",
              opacity: "0.25",
            },
            img: {
              transition: "all .3s ease-in-out",
              width: "100%",
              height: "100%",
            },
          },
        }}
      >
        {video.thumbnail && video.thumbnail.desktop && (
          <GatsbyImage image={video.thumbnail.desktop} alt="" />
        )}
        <Box
          sx={{
            svg: {
              stroke: "primary",
            },
            position: "absolute",
            top: [3],
            left: [3],
            zIndex: 1,
          }}
        >
          <Play size={28} />
        </Box>

        <Heading
          as="h2"
          variant={index === 8 || index === 1 ? "h3" : "h6"}
          color="light"
          sx={{
            position: "absolute",
            bottom: [3],
            left: [3],
            zIndex: 1,
            display: "-webkit-box",
            "-webkit-line-clamp": ["2"],
            "-webkit-boxOrient": "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {video.title}
        </Heading>
      </Box>
    </InboundLink>
  )
}

export default PinnedCategory
