import React from "react"
import { Box, Container, Flex, Heading, Grid } from "@theme-ui/components"
import PlaylistThumb from "../playlistThumb"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { useBreakpointIndex } from "@theme-ui/match-media"

const PlaylistCollection = ({ title, playlists }) => {
  const index = useBreakpointIndex()

  return (
    <Box sx={{ position: "relative", my: [7] }}>
      <Container sx={{}}>
        <Box
          sx={{
            pt: [6],
            borderTop: "1px solid",
            borderColor: "dark",
          }}
        />
      </Container>

      <Container
        variant={index > 1 ? "oneSideRight" : "container"}
        sx={{ mb: [6, 6, 7] }}
      >
        <Grid columns={["1fr", "1fr", ".2fr .8fr"]} gap={[0, 3]}>
          <Heading as="h2" variant="h2" sx={{ mt: [0], mb: [0, 5] }}>
            {title}
          </Heading>
          {index > 1 ? (
            <Box
              sx={{
                ".swiper-container": {
                  pb: [0],
                },
                ".swiper-slide": {
                  height: "auto",
                  paddingTop: ["10px"],
                },
              }}
            >
              <Swiper
                grabCursor={true}
                spaceBetween={16}
                preventClicks={true}
                preventClicksPropagation={true}
                autoHeight={false}
                breakpoints={{
                  320: {
                    slidesPerView: 1.2,
                  },
                  640: {
                    slidesPerView: 2.2,
                  },
                  896: {
                    slidesPerView: 3.3,
                  },
                }}
              >
                {playlists.map(playlist => (
                  <SwiperSlide key={playlist.title}>
                    <PlaylistThumb playlist={playlist} small />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          ) : (
            <Grid columns={["1fr"]}>
              {playlists.map(playlist => (
                <PlaylistThumb key={playlist.title} playlist={playlist} />
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default PlaylistCollection
