import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import VideoEmbed from "./embed"
import { getCategoryPath, getVideoPath } from "../../utils/path"
import { MagicLink } from "../../utils/magicLink"
import { InboundLink } from "../link"
import { GatsbyImage } from "gatsby-plugin-image"
import { PlayCircle } from "react-feather"

const PinnedVideo = ({ video }) => {
  return (
    <Box sx={{ position: "relative", my: [0, 6], mt: [0, 0, 6, 6] }}>
      <Container sx={{ px: [0, 6], pt: [0, 0] }}>
        <Grid
          columns={["1fr", "1fr", "1fr", ".5fr .5fr"]}
          sx={{ backgroundColor: "primary" }}
          gap={[0]}
        >
          <Flex
            sx={{
              gridRow: [2, 2, 2, 1],
              px: [3, 3, 3, 7],
              pr: [3, 3, 9, 9],
              py: [6, 6, 6, 7],
              pb: [7, 7, 7, 8],
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Heading
                as="h2"
                variant="h2"
                sx={{
                  my: [0],
                  fontSize: [5, 6],
                  color: "light",
                  display: "-webkit-box",
                  "-webkit-line-clamp": ["2"],
                  "-webkit-boxOrient": "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {video.title}
              </Heading>
            </Box>
            <Box
              sx={{
                mt: [5, 5, 5, 5],
              }}
            >
              <Text
                sx={{
                  fontSize: [0],
                  letterSpacing: "0.09",
                  lineHeight: "1",
                  color: "light",
                }}
              >
                {new Date(
                  video.meta && video.meta.firstPublishedAt
                    ? video.meta.firstPublishedAt
                    : video.firstPublishedAt && video.firstPublishedAt
                )
                  .toLocaleDateString()
                  .replaceAll("/", ".")}
              </Text>
            </Box>

            <Box
              as="div"
              sx={{
                fontSize: 2,
                color: "light",
                my: [0, 0, 0, 0],
                my: [0],
                display: "-webkit-box",
                "-webkit-line-clamp": "3",
                "-webkit-boxOrient": "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{ __html: video.description }}
            />
            <Box sx={{ py: [3] }}>
              {video.speaker.map(speaker => (
                <Box sx={{ fontFamily: "alternative" }}>
                  <Text color="light" sx={{ fontWeight: "600" }}>
                    {speaker.name}
                  </Text>
                </Box>
              ))}
            </Box>
            <Box>
              <InboundLink
                to={getCategoryPath(video.category, video.category.locale)}
                sx={{
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box variant="links.badge.smallCategory">
                  {video.category.title}
                </Box>
              </InboundLink>
            </Box>
          </Flex>
          <Box sx={{ height: "100%", width: "100%", gridRow: [1, 1, 1, 1] }}>
            <InboundLink
              to={getVideoPath(video, video.category.locale)}
              sx={{
                display: "inline-block",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  zIndex: 0,
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    height: "100%",
                    img: {
                      height: "100%",
                      objectPosition: "right",
                    },
                  },
                }}
              >
                {video.thumbnail && (
                  <GatsbyImage image={video.thumbnail.desktop} alt="" />
                )}

                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 1,
                  }}
                >
                  <PlayCircle size={50} color="white" />
                </Box>
              </Box>
            </InboundLink>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default PinnedVideo
