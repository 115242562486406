import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import { InboundLink } from "../link"
import { getCategoryPath } from "../../utils/path"
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../assets/style/swiper-bundle.min.css"
import { useBreakpointIndex } from "@theme-ui/match-media"

const VideoCategoryList = ({ block }) => {
  const { videoCategory } = block

  // console.log(videoCategory)
  const index = useBreakpointIndex()
  return (
    <Box sx={{ position: "relative", my: [7] }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container
              variant={index <= 1 ? "oneSideRight" : "container"}
              sx={{}}
            >
              <Box
                sx={{
                  pt: [6],
                  borderTop: "1px solid",
                  borderColor: "dark",
                  textAlign: ["left", "left", "center"],
                  pb: [0, 0, 6],
                  ":after": {
                    content: "''",
                    position: "absolute",
                    width: "120px",
                    right: "0",
                    bottom: "0",
                    height: "100px",
                    zIndex: 2,
                    background:
                      "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,.3) 50%, rgba(255,255,255,1) 100%)",
                  },
                }}
              >
                <Heading as="h2" variant="h2" sx={{ my: [0] }}>
                  {t.categoryInterest}
                </Heading>
                {index > 1 ? (
                  <Flex
                    sx={{ justifyContent: "center", flexWrap: "wrap", mt: [7] }}
                  >
                    {videoCategory.map(category => (
                      <InboundLink
                        to={getCategoryPath(category, category.locale)}
                        variant="links.badge.category"
                        sx={{
                          mx: [2],
                          my: [2],
                        }}
                      >
                        {category.title}
                      </InboundLink>
                    ))}
                  </Flex>
                ) : (
                  <Box
                    sx={{
                      mt: [6],
                      display: "flex",
                      overflow: "auto",
                      position: "relative",
                      scrollSnapType: "x mandatory",
                      ".item": {
                        flexShrink: 0,
                        scrollSnapAlign: "start",
                      },
                      "&::-webkit-scrollbar": {
                        width: "1em",
                      },

                      "&::-webkit-scrollbar-track": {
                        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        outline: "1px solid rgba(0, 0, 0, 0)",
                      },
                    }}
                  >
                    {videoCategory.map(category => (
                      <Box className="item">
                        <InboundLink
                          to={getCategoryPath(category, category.locale)}
                          variant="links.badge.category"
                          sx={{
                            mr: [2],
                          }}
                        >
                          {category.title}
                        </InboundLink>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default VideoCategoryList
